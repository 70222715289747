import api from "../httpClient.js";

export async function all() {
  const response = await api.get("/tocs");
  return response.data.tocs;
}
export async function one(id) {
  const response = await api.put(`/tocs/${id}`);
  return response.data.tocs;
}
export function update(id, tocs) {
  let body = {};
  for (let key in tocs) {
    body[key] = tocs[key];
  }
  return api.put(`/tocs/${id}`, body);
}
export function add(tocs) {
  let body = {};
  for (let key in tocs) {
    body[key] = tocs[key];
  }
  return api.post("/tocs/add", body);
}

export async function del(id) {
  return api.delete(`/tocs/${id}`);
}

// Get Images
export async function getImages(id) {
  const response = await api.get(`/tocs/${id}/imgs`);
  return response.data.tocs_imgs;
}

// Upload Image
export async function uploadImage(fd) {
  const response = await api.post("/tocs/uploadImage", fd);
  return response;
}
